import api from "../api";
import { AxiosResponse } from "axios";

import { ICreateUserWoPasswordPayload, ICreateUserWoPasswordResponse } from "../../interfaces/User";

const baseUrl = "users";

/* Changing structure to something more web-app alike */
class UserService {
  fetchRoles = (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`${baseUrl}/${id}/list_roles/`);
  };

  toggleSuperadmin = (id: string, data: {action: boolean}): Promise<AxiosResponse<any>> => {
    return api.patch(`${baseUrl}/${id}/toggle_superadmin/`, data);
  }

  fetchUserAppointments = (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`${baseUrl}/${id}/appointments/`);
  }

  createUserWoPassword = (data: ICreateUserWoPasswordPayload, salesSource: string): Promise<AxiosResponse<ICreateUserWoPasswordResponse>> => {
    return api.post(`users/register_wo_password/`, data, {params: {source: salesSource}});
  }
}

const userService = new UserService();

export default userService;
