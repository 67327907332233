import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Auth from "../../../utils/auth/auth";
import { calculateAge } from "../../../utils/dates/formatDate";
import appointmentService from "../../../utils/api/v1/appointmentService";
import userService from "../../../utils/api/v1/userService";
import { isEmpty } from "lodash";
import moment from "moment";

// typings
import Appointment from "../../../utils/interfaces/Appointment";
import { User, baseUser } from "../../../utils/interfaces/User";

// components
import { Button, FormControlLabel, Switch, Paper } from "@material-ui/core";
import PrivateRoute from "../../../components/Authentication/PrivateRoute";
import LoadingError from "../../../components/Loaders/LoadingError";
import Bold from "../../../components/Typography/Bold";
import Flex from "../../../components/Containers/Flex";
import SuperadminToggle from "../../../components/Users/SuperadminToggle";
import DashboardGrid from "../../../components/Appointments/DashboardGrid";
import api from "../../../utils/api/api";

const auth = new Auth();

interface Props {
  id: string;
}

const Content = styled.div`
  background-color: whitesmoke;
  border-radius: 1rem;
  padding: 1rem;
  display: inline-block;
  padding: 1em 2em 1em 2em;
  text-align: left;

  p {
    margin: 0.5rem;
    font-weight: 100;
  }

  h3 {
    margin-right: 2rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  .MuiButtonBase-root {
    float: right;
    margin: 20px 10px;
  }
`;

const Wrapper = styled(Paper)`
  text-align: center;
  padding: 4rem;
`;

const IndexPage = ({ id }: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Object>({});
  const [user, setUser] = useState<User>(baseUser);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [nextRequest, setNextRequest] = useState<string | null>(null);
  const [prevRequest, setPrevRequest] = useState<string | null>(null);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [sms, setSms] = useState<boolean>(true);
  const [fasting, setFasting] = useState<boolean>(false);

  const isSuperAdmin1 = auth.isSuperadmin1();
  const fetchUserAndAppointments = async (): Promise<void> => {
    setLoading(true);
    try {
      const userRequest = await appointmentService.fetchUser(id);
      const appointmentRequest = await userService.fetchUserAppointments(id);
      setUser(userRequest.data.data);
      setAppointments(appointmentRequest.data.results);
      setTotalRows(appointmentRequest.data.count);
      setNextRequest(appointmentRequest.data.next.replace("http://", "https://"));
      setPrevRequest(appointmentRequest.data.previous.replace("http://", "https://"));
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const fetchNewPage = async (action: "next" | "previous"): Promise<void> => {
    setLoading(true);
    try {
      const request = await api.request({
        method: "GET",
        url: action === "next" ? nextRequest : prevRequest,
      });
      setAppointments(request.data.results);
      setTotalRows(request.data.count);
      setNextRequest(request.data.next.replace("http://", "https://"));
      setPrevRequest(request.data.previous.replace("http://", "https://"));
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const action = currentPage > newPage ? "previous" : "next";
    fetchNewPage(action);
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchUserAndAppointments();
  }, []);

  return (
    <PrivateRoute>
      <LoadingError loading={loading} error={error} />
      {!loading && isEmpty(error) && (
        <>
          <Wrapper>
            <Content>
              <Flex justify="space-between" margin="0 0 1rem 0">
                <h3>Usuario</h3>
                <Flex justify="center" align="center">
                  {isSuperAdmin1 && <SuperadminToggle userId={user.id} />}
                </Flex>
              </Flex>
              <p>
                <Bold>Primer Nombre: </Bold> {user.first_name}
              </p>
              <p>
                <Bold>Segundo Nombre: </Bold> {user.second_name}
              </p>
              <p>
                <Bold>Primer Apellido: </Bold> {user.last_name}
              </p>
              <p>
                <Bold>Segundo Apellido: </Bold> {user.second_last_name}
              </p>
              <p>
                {(user.document_type === "ci" ||
                  user.document_type === undefined) && <Bold>RUT: </Bold>}
                {user.document_type === "passport" && <Bold>Pasaporte: </Bold>}
                {user.document_number}
              </p>
              <p>
                <Bold>Sexo: </Bold>{" "}
                {user.gender === "male" ? "Masculino" : "Femenino"}
              </p>
              <p>
                <Bold>Teléfono: </Bold> {user.phone}
              </p>
              <p>
                <Bold>Email: </Bold> {user.email}
              </p>
              <p>
                <Bold>Edad: </Bold> {calculateAge(user.date_of_birth)} años
              </p>
              <p>
                <Bold>Fecha de nacimiento: </Bold>{" "}
                {moment
                  .utc(user.date_of_birth)
                  .format("DD [de] MMMM [de] YYYY")}
              </p>
              <ButtonContainer>
                <Link to={`/users`}>
                  <Button variant="text" color="primary">
                    Volver
                  </Button>
                </Link>
                <Link to={`/users/${user.id}/edit`}>
                  <Button variant="outlined" color="primary">
                    Editar
                  </Button>
                </Link>
              </ButtonContainer>
            </Content>
          </Wrapper>
          <Flex justify="space-around" margin="2em 0em 2em 0em">
            <FormControlLabel
              label="Ayuno"
              control={
                <Switch
                  color="primary"
                  checked={fasting}
                  onChange={(e) => {
                    setFasting(e.target.checked);
                  }}
                />
              }
            />
            <FormControlLabel
              label="SMS"
              control={
                <Switch
                  color="primary"
                  checked={sms}
                  onChange={(e) => {
                    setSms(e.target.checked);
                  }}
                />
              }
            />
          </Flex>
          <DashboardGrid
            appointments={appointments}
            sms={sms}
            fasting={fasting}
            totalRows={totalRows}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
          />
        </>
      )}
    </PrivateRoute>
  );
};

export default IndexPage;
