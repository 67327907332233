import Swal from 'sweetalert2';

// legacy version, used a lot in the app before the swal version
const fetch = async (
  setLoading: (loading: boolean) => void,
  setError: (error: any) => void,
  callback: () => Promise<void>,
): Promise<void> => {
  setLoading(true);
  try {
    await callback();
  } catch (err) {
    console.log(err);
    setError(err);
  }
  setLoading(false);
}

// from now on use this one
export const fetchHelper = async (
  setLoading: (loading: boolean) => void,
  callback: () => Promise<void>,
  failCallback: (err: any | void) => boolean | void = () => {},
  postFailCallback: () => void = () => {},
): Promise<void> => {
  setLoading(true);
  try {
    await callback();
  } catch (err) {
    const cb = failCallback(err);
    if (!cb) {
      console.log({err});
      Swal.fire({
        title: 'Error',
        text: `Lo sentimos, algo ha salido mal. Por favor vuelve a intentarlo más tarde.`,
        footer: `<span style="font-size: 12px">Si el error persiste, por favor comunícate con el equipo de desarrollo, incluyendo la siguiente información:<br />${err.response?.data?.error || err.response?.data?.err || JSON.stringify(err.response?.data) || err}</span>`,
        icon: 'error',
      });
    } else {
      postFailCallback();
    }
  }
  setLoading(false);
}

export default fetch;
