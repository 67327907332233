import React, { useEffect, useState } from "react";
import userService from "../../utils/api/v1/userService";
import { fetchHelper } from "../../utils/api/v1/fetchHelper";

// components
import Button from '@material-ui/core/Button';

interface Props {
  userId: string;
}

const SuperadminToggle = (props: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isSuperadmin, setIsSuperadmin] = useState<boolean>(false);

  useEffect(() => {
    const fetchRoles = async (): Promise<void> => {
      await fetchHelper(setLoading, async () => {
        const req = await userService.fetchRoles(props.userId);
        const roles = req.data.data as string[];
        if (roles.includes("superadmin")) {
          setIsSuperadmin(true);
        } else {
          setIsSuperadmin(false);
        }
      })
    }

    fetchRoles();
  }, [])

  const handleToggle = async (): Promise<void> => {
    const data = {
      action: isSuperadmin ? false : true,
    }
    await fetchHelper(setLoading, async () => {
      await userService.toggleSuperadmin(props.userId, data);
      setIsSuperadmin(!isSuperadmin);
    })
  }

  return (
    <Button
      disabled={loading}
      variant="text"
      color="primary"
      onClick={handleToggle}
    >
      {isSuperadmin ? "Quitar Superadmin" : "Hacer Superadmin"}
    </Button>
  )
}

export default SuperadminToggle;
